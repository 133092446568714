// Generated by Framer (6880a8a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/unMHkq95m";

const cycleOrder = ["CrxJo4nvp"];

const variantClassNames = {CrxJo4nvp: "framer-v-4thfsu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; text?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "CrxJo4nvp", text: YG2MuVEU4 = "Singel 258, 1016 AB Amsterdam, Netherlands", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CrxJo4nvp", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8eDMv", sharedStyle.className, classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-4thfsu", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CrxJo4nvp"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-am2lh5"} data-styles-preset={"unMHkq95m"}>Singel 258, 1016 AB Amsterdam, Netherlands</motion.p></React.Fragment>} className={"framer-smed84"} data-framer-name={"Singel 258, 1016 AB Amsterdam, Netherlands"} layoutDependency={layoutDependency} layoutId={"vwyw1vvsM"} style={{"--framer-paragraph-spacing": "0px"}} text={YG2MuVEU4} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8eDMv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8eDMv * { box-sizing: border-box; }", ".framer-8eDMv .framer-c22h1g { display: block; }", ".framer-8eDMv .framer-4thfsu { height: 96px; overflow: hidden; position: relative; width: 492px; }", ".framer-8eDMv .framer-smed84 { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre-wrap; width: 492px; word-break: break-word; word-wrap: break-word; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 492
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"YG2MuVEU4":"text"}
 */
const FramerAU8u7TkYu: React.ComponentType<Props> = withCSS(Component, css, "framer-8eDMv") as typeof Component;
export default FramerAU8u7TkYu;

FramerAU8u7TkYu.displayName = "test";

FramerAU8u7TkYu.defaultProps = {height: 96, width: 492};

addPropertyControls(FramerAU8u7TkYu, {YG2MuVEU4: {defaultValue: "Singel 258, 1016 AB Amsterdam, Netherlands", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerAU8u7TkYu, [...sharedStyle.fonts])